import React, { useState } from "react";
import "./App.css";
import Homepage from "./components/Homepage";
import Navbar from "./components/Navbar";

function App() {


  return (
    <div className="App">
      <Navbar />
      <Homepage />
    </div>
  );
}

export default App;
